import { Component, OnDestroy, OnInit } from '@angular/core';
import { NoSessionAuthService } from '../../../koppel-auth/no-session-auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'par-second-auth-callback',
  template: '',
  styles: ['']
})
export class SecondAuthCallbackComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(private noSessionAuthService: NoSessionAuthService) {}

  ngOnInit(): void {
    this.noSessionAuthService.authorize();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
