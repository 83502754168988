import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, ENV } from '@core/config/app.config';
import { NotificationService } from '@core/error/notifications/notification.service';

@Injectable({
  providedIn: 'root'
})
export class NoSessionAuthService {
  private config: AppConfig = inject(ENV);
  private handler = inject(HttpBackend);
  private router = inject(Router);
  private oauthService = inject(OAuthService);
  private notifications = inject(NotificationService);

  schoolname: string;

  constructor(

  ) {}

  public getInitCodeFlowURL(): String {
    const config = {
      client_id: this.config.auth.clientId,
      customQueryParams: { claims: '{ "id_token": { "name": null} }', session: 'no_session', prompt: 'select_account' },
      redirect_uri: window.location.origin + '/oauth2-second',
      scope: 'openid offline_access',
      response_type: 'code'
    };

    const url = this.config.auth.IDP_url + '/authorize?' + this.serializeConfig(config).toString();

    return url;
  }

  public authorize() {
    const params = new URLSearchParams(location.search);

    const config = new HttpParams({
      fromObject: {
        grant_type: 'authorization_code',
        code: params.get('code') as any,
        redirect_uri: window.location.origin + '/oauth2-second',
        client_id: this.config.auth.clientId,
        claims: '{ "id_token": { "name": null} }'
      }
    });

    const http = new HttpClient(this.handler);
    http
      .post(this.config.auth.IDP_url + '/token', config, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .subscribe({
        next: (result: any) => {
          sessionStorage.setItem('secondaryToken', result.access_token);
          this.router.navigate(['/koppel-confirm']);
        },
        error: error => {
          this.notifications.error('snackbar.koppel.generic_error');
          this.clearAndGoHome();
        }
      });
  }

  public getIdentity(): Observable<ParnassysIdentity> {
    const token = this.parseJwt(sessionStorage.getItem('secondaryToken'));

    const http = new HttpClient(this.handler);
    return http
      .get(this.config.parnassysIDPEndpoint + '/identiteit/me', {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + sessionStorage.getItem('secondaryToken')
        })
      })
      .pipe(
        map(
          (result: any) => {
            for (let i = 0; i < result.accounts.length; i++) {
              if (result.accounts[i].links[0].id == parseInt(token['sub'] as any)) {
                // Found the profile!
                this.schoolname = result.accounts[i].organisatie.naam;
                return result.accounts[i];
              }
            }

            return throwError(() => new Error());
          },
          error => {
            this.notifications.error('snackbar.koppel.generic_error');
            this.clearAndGoHome();
          }
        )
      );
  }

  public koppel() {
    const http = new HttpClient(this.handler);
    http
      .post(this.config.parnassysIDPEndpoint + '/identiteit/checkProfielTokenAndKoppel', null, {
        headers: {
          Authorization: 'Bearer ' + this.oauthService.getAccessToken(),
          additionalProfileToken: sessionStorage.getItem('secondaryToken') as any,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .subscribe({
        next: () => {
          this.notifications.success({ msg: 'snackbar.koppel.added', prop: this.schoolname});

          this.logout().subscribe(() => {
            this.clearAndGoHome();
          });
        },
        error: error => {
          this.notifications.success('snackbar.koppel.generic_error');
          this.clearAndGoHome();
        }
      });
  }

  public logout(): Observable<boolean> {
    const http = new HttpClient(this.handler);
    return http
      .delete(this.config.parnassysIDPEndpoint + '/account/logout', {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('secondaryToken')
        }
      })
      .pipe(
        map(() => {
          return true;
        }),
        error => {
          return error;
        }
      );
  }

  public clearAndGoHome() {
    sessionStorage.removeItem('secondaryToken');
    this.router.navigate(['/']);
  }

  private serializeConfig(o, searchParam = new URLSearchParams()) {
    Object.entries(o).forEach(([k, v]) => {
      if (v !== null && typeof v === 'object') this.serializeConfig(v, searchParam);
      else searchParam.append(k, v as any);
    });

    return searchParam;
  }

  public parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
