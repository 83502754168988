import { map, shareReplay } from 'rxjs/operators';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { HttpClient } from '@angular/common/http';
import { IdentityMapper } from '@domain/mappers/identity-mapper';
import { ProfileMapper } from '@domain/mappers/profile-mapper';
import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { AppConfig, ENV } from '@core/config/app.config';

@Injectable({ providedIn: 'root' })
export class IdentityRestService {
  private config: AppConfig = inject(ENV);
  private http = inject(HttpClient);
  private identityMapper = inject(IdentityMapper);
  private profileMapper = inject(ProfileMapper);

  fetchIdentity(): Observable<ParnassysIdentity> {
    return this.http.get(this.config.parnassysIDPEndpoint + '/identiteit/me').pipe(
      map((response: any) => {
        const identity = this.identityMapper.mapToParnassysEntity(response);
        identity.profiles = [];
        response.accounts.forEach(element => {
          identity.profiles.push(this.profileMapper.mapToParnassysEntity(element));
        });
        return identity;
      })
    );
  }

  validatePassword(password: string): Observable<string[]> {
    return this.http.post<string[]>(this.config.parnassysIDPEndpoint + '/identiteit/validatePassword', { password: password }).pipe(
      shareReplay(),
      map((response: any) => {
        return response?.meldingen ?? [];
      })
    );
  }

  updatePidm(pidm: string): Observable<any> {
    return this.http
      .put(
        this.config.parnassysIDPEndpoint + '/identiteit/pasIdentiteitEmailAan',
        { email: pidm, pasContactMailAan: true },
        { responseType: 'text' }
      ).pipe(shareReplay());
  }

  updatePassword(currentpwd: string, newpwd: string): Observable<any> {
    return this.http.post(
      this.config.parnassysIDPEndpoint + '/identiteit/checkAndSetPassword',
      { currentPassword: currentpwd, newPassword: newpwd }
    ).pipe(shareReplay());
  }
}
