import { IdentityRestService } from '@core/http/identity-rest.service';
import { GoogleAnalyticsService } from '@core/logging/google-analytics.service';
import { throwError } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { iTranslatable } from '@core/translate/itranslated';
import { catchError, delay, finalize, tap } from 'rxjs/operators';
import { UserFeedbackError } from '@core/error/userfeedback.error';
import { Router } from '@angular/router';
import { NotificationService } from '@core/error/notifications/notification.service';

@Component({
  selector: 'par-edit-email',
  templateUrl: 'edit-email.component.html',
  styles: ['.flex { display: flex }']
})
export class EditEmailComponent implements iTranslatable, OnInit {
  readonly langModule = ['account', 'identity_edit', 'edit_email'];
  public emailForm: FormGroup;
  public isEmailLoading = false;
  @Input() email = '';

  constructor(
    private router: Router,
    private identityRestService: IdentityRestService,
    private GA: GoogleAnalyticsService,
    private notifications: NotificationService
  ) {}

  ngOnInit(): void {
    this.emailForm = new FormGroup({
      pidm: new FormControl(this.email, { validators: [Validators.required] })
    });
  }

  cancel() {
    this.router.navigate(['/']);
  }

  updatePidm(): void {
   this.isEmailLoading = true;
    this.identityRestService
      .updatePidm(this.emailForm.value.pidm)
      .pipe(
        catchError(() => {
          this.GA.RegisterEvent('Identity', 'Edit', 'PIDM', 0);
          return throwError(new UserFeedbackError('snackbar.email.bad'));
        }),
        tap(() => {
          this.GA.RegisterEvent('Identity', 'Edit', 'PIDM', 1);
          this.notifications.success('snackbar.email.good');
          this.router.navigate(['/']);
        }),
        delay(1000),
       finalize(() => (this.isEmailLoading = false))
      )
      .subscribe();
  }
}
