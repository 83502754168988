import { iTranslatable } from '@core/translate/itranslated';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FtIconRegistry, iconFtCross } from '@fortytwo/ui';
@Component({
  selector: 'par-sign-out',
  templateUrl: './password-info.component.html',
  styleUrls: ['./password-info.component.scss']
})
export class PasswordInfoModalComponent implements iTranslatable {
  readonly langModule = ['modals', 'password'];
  medewerker : boolean;

  constructor(iconRegistry: FtIconRegistry, @Inject(MAT_DIALOG_DATA) private readonly data: any) {
    iconRegistry.registerIcons([iconFtCross]);
    this.medewerker=data.medewerker;
  }
}
