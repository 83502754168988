import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'par-auth-callback',
  template: '',
  styles: ['']
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
  private authService = inject(AuthService);
  private router = inject(Router);

  private loadingSub: Subscription;
  public strokeWidth = 3;
  public diameter = 60;

  ngOnInit(): void {
    this.loadingSub = this.authService
      .fetchToken$()
      .subscribe(authState => this.router.navigate(['/']))
  }

  ngOnDestroy(): void {
    if(this.loadingSub)
      this.loadingSub.unsubscribe();
  }
}
