import { Component, Input } from '@angular/core';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { iTranslatable } from '@core/translate/itranslated';

@Component({
  selector: 'par-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements iTranslatable {
  readonly langModule = ['account', 'home', 'profile'];
  @Input() identity: ParnassysIdentity;

  getNumber(index: number): string {
    return '_' + (index > 5 ? index - 5 : index);
  }
}
