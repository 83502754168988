import { NotificationService } from '../../core/error/notifications/notification.service';
import { Component, OnInit, inject } from '@angular/core';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { NoSessionAuthService } from '@core/koppel-auth/no-session-auth.service';
import { iTranslatable } from '@core/translate/itranslated';
import { Observable } from 'rxjs';
import { AccountStoreService } from '@core/store/account-store.service';

@Component({
  selector: 'par-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements iTranslatable, OnInit {
  readonly langModule = ['account', 'home'];
  identity$: Observable<ParnassysIdentity>;
  private accountStore = inject(AccountStoreService);
  private noSessionAuthService = inject(NoSessionAuthService);

  ngOnInit(): void {
    this.identity$ = this.accountStore.getIdentity$();
  }

  getInitCodeFlowURL(): String {
    return this.noSessionAuthService.getInitCodeFlowURL();
  }
}
