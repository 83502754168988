<form [formGroup]="passwdForm" class="password-form card-sub-content">
  <div class="password-edit-block">
    <ft-formfield [nolabel]="true">
      <input ftInputfield type="password" id="current_password" formControlName="current_password" autocomplete="off" />
      <label for="current_password" class="ft-formfield__label">{{ 'lbl_currentpwd' | prefix: this | translate }}</label>
    </ft-formfield>
  </div>

  <div class="password-edit-block">
    <ft-formfield [nolabel]="true">
      <input ftInputfield class="pwd-strength__trigger" type="password" id="password" formControlName="password" autocomplete="off" #password />
      <label for="password" class="ft-formfield__label">{{ 'lbl_newpwd' | prefix: this | translate }}</label>
    </ft-formfield>
    <div class="pwd-strength" [ngClass]="PasswordPercentage == 0 ? 'pwd-strength--empty' : 'pwd-strength--bad'">
      <div class="pwd-strength__container">
        <progress
          id="password-progression "
          class="pwd-strength__progress"
          [ngClass]="PasswordPercentage < 100 ? 'pwd-strength__progress--weak' : 'pwd-strength__progress--strong'"
          value="{{ PasswordPercentage }}"
          max="100"> {{ PasswordPercentage }}%
        </progress>
        <p class="pwd-strength__label is-small">{{ lblPasswordChecker | prefix: this | translate }} </p>
      </div>
    </div>
  </div>

  <div class="password-edit-block">
    <ft-formfield [nolabel]="true">
      <input ftInputfield class="pwd-strength__trigger" type="password" id="password_repeat" formControlName="password_repeat" autocomplete="off" #password_repeat/>
      <label for="password_repeat" class="ft-formfield__label">{{ 'lbl_repeatpwd' | prefix: this | translate }}</label>
      <span ft-input-error>{{ 'lbl_chk_pwdunequal' | prefix: this | translate }}</span>
    </ft-formfield>
  </div>
</form>

<div class="card-row-content">
    <button
      ft-button
      appearance="secondary"
      [state]="isPwdLoading ? 'loading' : 'active'"
      id="edit-password"
      (click)="savePassword()">
      {{'btn_savepwd' | prefix: this | translate}}
    </button>

    <button
      ft-button
      appearance="destructive"
      (click)="cancel()">
      {{ 'btn_cancel' | prefix: this | translate }}
    </button>
</div>
