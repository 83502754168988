import { SignoutModalComponent } from '@shared/components/dialogs/sign-out/sign-out.component';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FtIconRegistry, iconFtArrowLeft, iconFtSignOut } from '@fortytwo/ui';

@Component({
  selector: 'par-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() titleShort: string;
  @Input() prev: string[];

  private subscriptions: Subscription = new Subscription();
  public lastname: string;

  constructor(private router: Router, private translateService: TranslateService, public dialog: MatDialog, iconRegistry: FtIconRegistry) {
    iconRegistry.registerIcons([iconFtArrowLeft, iconFtSignOut]);
  }

  ngOnInit(): void {
    this.title = this.translateService.instant('application.title');
    this.titleShort = this.translateService.instant('application.title_short');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  home(): void {
    this.router.navigate(['/']);
  }

  moveBack(): void {
    if(!!this.prev && this.prev.length > 0) {
      this.router.navigate(this.prev);
      return;
    }
    this.home();
  }

  SignUserOut(): void {
    this.dialog.open(SignoutModalComponent, {
      panelClass: 'small'
    });
  }
}
