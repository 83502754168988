import { ProfileRestService } from '@core/http/profile-rest.service';
import { AccountStoreService } from '@core/store/account-store.service';
import { ParnassysProfile } from '@domain/parnassys-profile';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFeedbackError } from '@core/error/userfeedback.error';
import { iTranslatable } from '@core/translate/itranslated';
import { Subscription, throwError } from 'rxjs';
import { catchError, combineLatestWith, delay, finalize, take, tap } from 'rxjs/operators';
import { NotificationService } from '@core/error/notifications/notification.service';

@Component({
  selector: 'par-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements iTranslatable, OnInit, OnDestroy {
  readonly langModule = ['account', 'profile_edit'];
  private profileSub: Subscription;
  private identitySub: Subscription;

  public isEditEmailLoading = false;
  public isDeleteEmailLoading = false;

  public profile!: ParnassysProfile;
  public identity: ParnassysIdentity;
  public prev: any;
  public contactEmailForm: FormGroup;
  public screenNameForm: FormGroup;
  public showEmail = false;
  public setupContactEmail = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountStore: AccountStoreService,
    private profileRestService: ProfileRestService,
    private snackbar: NotificationService
  ) {}

  ngOnInit(): void {
    const id = parseInt(this.route.snapshot.params.id);
    this.prev = { route: '//', queryParams: {} };

    this.contactEmailForm = new FormGroup({ contactEmail: new FormControl(null, { validators: [Validators.required, Validators.email] }) });
    this.screenNameForm = new FormGroup({
      screenName: new FormControl(null, { validators: [Validators.required, Validators.minLength(5), Validators.maxLength(30)] })
    });

    const identity = this.accountStore.getIdentity$();

    identity.pipe(take(1)).subscribe((identity) => {
      const activeProfile = identity.profiles.find(profile => profile.id == id);
      console.log(activeProfile);
      if (!activeProfile || identity.pidm === activeProfile.email) {
        this.router.navigate(['/']);
        this.snackbar.warn('Profile not found');
      }
      this.profile = activeProfile as ParnassysProfile;

      this.contactEmailForm.setValue({ contactEmail: this.profile?.email });
      this.screenNameForm.setValue({ screenName: this.profile?.screenName });

      this.identity = identity;
    });
  }

  ngOnDestroy(): void {
    if (this.profileSub) this.profileSub.unsubscribe();
    if (this.identitySub) this.identitySub.unsubscribe();
  }

  saveEmail(): void {
    this.isEditEmailLoading = true;
    const email = this.contactEmailForm.value.contactEmail;

    this.profileRestService
      .updateCM(email, this.profile.id)
      .pipe(
        delay(1000),
        catchError(XHR => {
          const error = JSON.parse(XHR.error).applicationError;

          if (XHR.status === 400 && error === 'MEDEWERKER_EMAIL_IN_GEBRUIK') {
            return throwError(() => new UserFeedbackError('snackbar.email.used'));
          }

          return throwError(() => new UserFeedbackError('snackbar.email.bad'));
        }),
        tap(() => {
          this.profile.email = email;
          this.showEmail = email !== this.identity.pidm;
          this.setupContactEmail = false;
          this.accountStore.setProfile(this.profile);
          this.snackbar.success('snackbar.email.updated');

          if (!this.showEmail) {
            this.router.navigate(['/']);
          }
        }),
        finalize(() => {
          this.isEditEmailLoading = false;
          this.contactEmailForm.setValue({ contactEmail: email });
        })
      )
      .subscribe();
  }

  deleteEmail(): void {
    this.isDeleteEmailLoading = true;

    this.profileRestService
      .updateCM(this.identity.pidm, this.profile.id)
      .pipe(
        delay(1000),
        catchError(XHR => {
          const error = JSON.parse(XHR.error).applicationError;

          if (XHR.status === 400 && error === 'MEDEWERKER_EMAIL_IN_GEBRUIK') {
            return throwError(new UserFeedbackError('snackbar.email.used'));
          }

          return throwError(new UserFeedbackError('snackbar.email.bad'));
        }),
        tap(() => {
          this.profile.email = this.identity.pidm;
          this.showEmail = false;
          this.accountStore.setProfile(this.profile);
          this.snackbar.success('snackbar.email.updated');
          this.router.navigate(['/']);
        }),
        finalize(() => (this.isDeleteEmailLoading = false))
      )
      .subscribe();
  }

  toggleEmail(): void {
    this.setupContactEmail = true;
    this.showEmail = !this.showEmail;
  }

  cancelEditContactEmail(): void {
    this.setupContactEmail = false;
    this.showEmail = false;
  }
}
