<par-main-menu title="{{ 'navbar.pagetitle' | prefix: this | translate }}" [prev]="['/']"></par-main-menu>

<div class="container">
    <div class="row row--center card top-24">
        <h2 class="card__title">{{ 'koppel.btn_add_account' | prefix: this | translate }}</h2>
        <p class="card__title-sub">{{ 'koppel.sub_header_confirm' | prefix: this | translate }}</p>

        <p *ngIf="identity">{{ 'koppel.txt_almost_done' | prefix: this | translate }}<b>{{ identity.pidm }}</b></p>

        <div class="selected-account card" *ngIf="secondaryIdentity">
            <div class="selected-account__check">
                <div class="circle">
                    <img src="/assets/icons/icon_check.svg" />

                </div>
            </div>
            <div class="selected-account__image">
                <img src="/assets/icons/icon_school_1.svg" />
            </div>
            <p class="selected-account__school bold">{{ secondaryIdentity.organisatie.naam }}</p>
            <p class="selected-account__name is-sm">{{ secondaryIdentity.weergavenaam }}</p>
            <p class="selected-account__role" [ngClass]="secondaryIdentity.readableType == 'Verzorger' ? 'green' : 'blue'">{{ secondaryIdentity.readableType }}</p>
        </div>
        <button
          ft-button
          appearance="primary"
          (click)="koppel()">
          {{ 'koppel.btn_confirm' | prefix: this | translate }}
        </button>
        <button
          ft-button
          appearance="secondary"
          (click)="cancel()">
          {{ 'koppel.btn_cancel' | prefix: this | translate }}
        </button>
    </div>
</div>
