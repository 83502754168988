import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    RouterModule.forChild([
      { path: 'login', canActivate: [], component: LoginComponent }
    ]),
    SharedModule
  ]
})
export class LoginModule {}
