import { LanguagePrefixerPipe } from './language/language-prefixer.pipe';
import { NgModule } from '@angular/core';
import { ShortenPipe } from './text/shorten.pipe';
import { ProfileShortenPipe } from './text/profile-shorten.pipe';

@NgModule({
  declarations: [ShortenPipe, ProfileShortenPipe, LanguagePrefixerPipe],
  exports: [ShortenPipe, ProfileShortenPipe, LanguagePrefixerPipe]
})
export class PipeModule {}
