import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserFeedbackError } from '@core/error/userfeedback.error';
import { GoogleAnalyticsService } from '@core/logging/google-analytics.service';
import { IdentityRestService } from '@core/http/identity-rest.service';
import { iTranslatable } from '@core/translate/itranslated';
import { PasswordHelper } from '@core/helpers/password.helper';
import { fromEvent, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, finalize, map, tap } from 'rxjs/operators';
import { NotificationService } from '@core/error/notifications/notification.service';

@Component({
  selector: 'par-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements iTranslatable, AfterViewInit, OnDestroy {
  readonly langModule = ['account', 'identity_edit', 'edit_password'];
  public isPwdLoading = false;
  public PasswordPercentage = 0;
  public isPasswordRepeatOk = true;
  public lblPasswordChecker = 'lbl_chk_pwdweak';
  public passwdForm: FormGroup;
  public passwordSub: Subscription;
  public passwordRepeatSub: Subscription;

  @ViewChild('password') password: ElementRef;
  @ViewChild('password_repeat') pwd_repeat: ElementRef;

  constructor(
    private router: Router,
    private identityService: IdentityRestService,
    private notifications: NotificationService,
    private GA: GoogleAnalyticsService
  ) {
    this.passwdForm = new FormGroup({
      current_password: new FormControl(null, { validators: [Validators.required] }),
      password: new FormControl(null, { validators: [Validators.required] }),
      password_repeat: new FormControl(null, { validators: [Validators.required] })
    });
  }

  ngAfterViewInit(): void {
    this.passwordSub = fromEvent(this.password.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((text: string) => {
          if (this.passwdForm.controls.password?.value) {
            this.updateFieldStatus(true);
          }
        })
      )
      .subscribe();

    this.passwordRepeatSub = fromEvent(this.pwd_repeat.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(250),
        distinctUntilChanged(),
        tap(text => {
          this.updateFieldStatus(false);
        })
      )
      .subscribe();
  }

  cancel() {
    this.router.navigate(['/']);
  }

  private updateFieldStatus(checkPasswordValidity: boolean): void {
    const pwd = this.passwdForm.controls.password;

    if (this.passwdForm.controls.password_repeat.dirty) {
      this.passwdForm.controls['password_repeat'].setErrors(null);
      this.isPasswordRepeatOk = this.passwdForm.controls.password_repeat.value === pwd.value;

      if (!this.isPasswordRepeatOk) {
        this.passwdForm.controls['password_repeat'].setErrors({ incorrect: true });
      }
    }

    if (checkPasswordValidity) this.checkPasswordPercentage(pwd);
  }

  private checkPasswordPercentage(pwd: AbstractControl): void {
    pwd.setErrors(null);
    const percDedicatedToPasswordTooShort = 70;

    this.identityService
      .validatePassword(pwd.value)
      .pipe(map((response: string[]) => PasswordHelper.calcProgression(response, pwd.value, percDedicatedToPasswordTooShort)))
      .subscribe((percentage: number) => {
        this.PasswordPercentage = percentage;

        if (this.PasswordPercentage < 100) {
          this.lblPasswordChecker = this.PasswordPercentage < percDedicatedToPasswordTooShort ? 'lbl_chk_pwdshort' : 'lbl_chk_pwdweak';

          this.passwdForm.controls['password'].setErrors({ incorrect: true });
        } else {
          this.lblPasswordChecker = 'lbl_chk_pwdstrong';
        }
      });
  }

  savePassword(): void {
    if (this.passwdForm.controls['password_repeat'].value !== this.passwdForm.controls['password'].value) {
      this.notifications.error('snackbar.password.notequal');
      return;
    }

    if (this.PasswordPercentage < 100) {
      this.notifications.error('snackbar.password.weak');
      return;
    }

    if (this.passwdForm.controls['current_password'].invalid) {
      this.notifications.error('snackbar.password.empty');
      return;
    }

    this.isPwdLoading = true;
    this.identityService
      .updatePassword(this.passwdForm.controls['current_password'].value, this.passwdForm.controls['password'].value)
      .pipe(
        catchError(response => {
          this.GA.RegisterEvent('Identity', 'Edit', 'Password', 0);
          this.isPwdLoading = false;

          return response.error?.applicationError
            ? throwError(new UserFeedbackError('snackbar.password.' + response.error.applicationError.toLowerCase()))
            : throwError(new UserFeedbackError('snackbar.password.bad'));
        }),
        tap(() => {
          this.GA.RegisterEvent('Identity', 'Edit', 'Password', 1);
          this.notifications.success('snackbar.password.good');
          this.router.navigate(['/']);
        }),
        delay(1500),
        finalize(() => (this.isPwdLoading = false))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.passwordSub) {
      this.passwordSub.unsubscribe();
    }
    if (this.passwordRepeatSub) {
      this.passwordRepeatSub.unsubscribe();
    }
  }
}
