import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, map, switchMap } from 'rxjs';

import { AuthService, AuthState } from './auth.service';
import { AccountStoreService } from '@core/store/account-store.service';
import { ParnassysIdentity } from '@domain/parnassys-identity';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private auth: AuthService = inject(AuthService)

  private accountStore = inject(AccountStoreService)

  private router = inject(Router)

  canActivate(_: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.verify$().pipe(
      switchMap((state: AuthState) => {
        if(state != AuthState.Authenticated) {
          this.router.navigate(['/', 'login']);
        }

        return this.accountStore.update();
      }),
      map((_: ParnassysIdentity) => true)
    )
  }
}
