declare var window: any;

export class HostHelper {
  static getStoreApp(): string {
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    return /iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor) && !window.MSStream
      ? 'https://apps.apple.com/nl/app/google-authenticator/id388497605'
      : 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
  }

  static CloseWindowFromWebview(): void {
    try {
      window.AndroidAccountApp.terminateWebView();
    } catch (e) {
      console.error("Tried to sign out on 'applike host but failed. '");
    }
  }
}
