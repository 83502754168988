import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {
  transform(value: string, maxlength: number = 25): string {
    if (maxlength < 2) {
      maxlength = 2;
    }
    if (value.length > maxlength) {
      return value.substring(0, maxlength) + '...';
    }
    return value;
  }
}
