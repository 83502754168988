import { SetupTwoFactorComponent } from './identity-edit/setup-twofactor/setup-twofactor.component';
import { EditEmailComponent } from './identity-edit/edit-email/edit-email.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { EditPasswordComponent } from './identity-edit/edit-password/edit.password.component';
import { IdentityComponent } from './index/identity/identity.component';
import { ProfilesComponent } from './index/profiles/profiles.component';
import { KoppelAuthModule } from '@core/koppel-auth/koppel-auth.module';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AuthModule } from '@auth/auth.module';
import { FeaturesRoutingModule } from './features-routing.module';
import {
  FtFormFieldModule, FtIconModule, FtMessagingModule, FtToggleModule
} from '@fortytwo/ui';
import { FtCardModule } from '@fortytwo/ui/patterns';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { IndexComponent } from './index/index.component';
import { KoppelConfirmComponent } from './koppel-confirm/koppel-confirm.component';
import { IdentityEditComponent } from './identity-edit/identity-edit.component';
import { TwofactorModalComponent } from './identity-edit/setup-twofactor/twofactor-modal/twofactor-modal.component';
import { DisableTwofactorModalComponent } from './identity-edit/setup-twofactor/disable-twofactor-modal/disable-twofactor-modal.component';


@NgModule({
  imports: [
    FeaturesRoutingModule,
    FtToggleModule,
    FtMessagingModule,
    FtIconModule,
    TranslateModule,
    SharedModule,
    MatDialogModule,
    ReactiveFormsModule,
    QRCodeModule,
    FtIconModule,
    FtFormFieldModule,
    FtCardModule,
    AuthModule,
    KoppelAuthModule
  ],
  exports: [],
  declarations: [
    // Index page
    ProfilesComponent,
    IndexComponent,
    IdentityComponent,

    // Edit Identity
    IdentityEditComponent,
    EditEmailComponent,
    EditPasswordComponent,
    SetupTwoFactorComponent,
    TwofactorModalComponent,
    DisableTwofactorModalComponent,

    // Edit profile
    ProfileEditComponent,

    // Koppelflow
    KoppelConfirmComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FeaturesModule {}
