import { ToastComponent } from '../core/error/notifications/toast.component';
import { MaterialModule } from '@shared/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PasswordInfoModalComponent } from './components/dialogs/password-info/password-info.component';
import { SignoutModalComponent } from './components/dialogs/sign-out/sign-out.component';
import { PipeModule } from './pipes/pipe.module';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from '@shared/components/error/not-found/not-found.component';
import { MainMenuComponent } from '@core/layout/main-menu/main-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { FtButtonModule, FtIconModule } from '@fortytwo/ui';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    PipeModule,
    TranslateModule,
    FtButtonModule,
    FtIconModule,
    BrowserAnimationsModule,
    CdkTableModule,
    FormsModule,
    OverlayModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    RouterModule,
    PipeModule,
    ToastComponent,
  ],
  exports: [
    MainMenuComponent,
    ToastComponent,
    SignoutModalComponent,
    PasswordInfoModalComponent,
    FtButtonModule,
    BrowserAnimationsModule,
    CommonModule,
    CdkTableModule,
    FormsModule,
    OverlayModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    RouterModule,
    PipeModule,
  ],
  declarations: [
    NotFoundComponent,
    SignoutModalComponent,
    PasswordInfoModalComponent,
    MainMenuComponent
  ]
})
export class SharedModule {}
