import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppConfig, ENV } from '@core/config/app.config';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private config: AppConfig = inject(ENV);
  private authService = inject(AuthService);

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !request.url.startsWith(this.config.parnassysIDPEndpoint) &&
      !request.url.startsWith(this.config.parnassysEndpoint)
    ) {
      return next.handle(request);
    }

    const authReq = request.clone({
      headers: this.appendAuthHeaders(request.headers)
    });

    if (this.authService.expired) {
      if (this.refreshTokenInProgress) {
        return this.refreshTokenSubject.pipe(
          filter(result => result !== null),
          take(1),
          switchMap(() => next.handle(authReq))
        );
      } else {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);


        return this.authService.refreshToken()
          .pipe(
            switchMap((_) => {
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(true);
              return next.handle(authReq);
            }),
            catchError(error => {
              this.authService.signOut();
              throw error;
            })
          );
      }
    }

    return next.handle(authReq);
  }

  private appendAuthHeaders(originalHeaders: HttpHeaders): HttpHeaders {
    return originalHeaders
      .set('Authorization', this.authService.header)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');
  }
}
