<par-main-menu title="{{ 'navbar.pagetitle' | prefix: this | translate }}" [prev]="['/']"></par-main-menu>

<div class="container" *ngIf="profile">
    <div class="profile">
        <div class="profile__image">
            <img [src]="'/assets/icons/icon_school_1.svg'" />
        </div>
        <p class="profile__school bold">{{ profile.organisation.name }}</p>
        <p class="profile__name">{{ profile.screenName }}</p>
        <p class="profile__role" [ngClass]="profile.role == 'Verzorger' ? 'green' : 'blue'">
            {{ profile.role == "Medewerker" ? ('lbl_role_employee' | prefix: this | translate) : ('lbl_role_guardian' | prefix: this| translate) }}
        </p>
    </div>
      <ft-card>
        <ft-card-content class="card--flex">
          <h3 class="card__title">{{ 'subtitle_email' | prefix: this | translate }}</h3>

          <div class="card-sub-content" *ngIf="showEmail || identity.pidm !== profile.email; else noEmail">
              <form [formGroup]="contactEmailForm">

                <ft-formfield [nolabel]="true">
                  <input ftInputfield class="pwd-strength__trigger" type="text" id="contactEmail" formControlName="contactEmail"/>
                  <label for="contactEmail" class="ft-formfield__label">{{ 'lbl_second_email' | prefix: this | translate }}</label>
                  <span ft-input-error>{{'error_invalid_email' | prefix: this | translate}}</span>
                </ft-formfield>

              </form>

              <div class="card-row-content">
                  <button
                    ft-button
                    *ngIf="setupContactEmail && profile.email === identity.pidm"
                    appearance="secondary"
                    id="cancel-email-button"
                    (click)="cancelEditContactEmail()">
                    {{ 'btn_cancel' | prefix: this | translate }}
                  </button>

                  <button
                    ft-button
                    *ngIf="!setupContactEmail"
                    appearance="secondary"
                    [state]="isDeleteEmailLoading ? 'loading' : 'active'"
                    id="delete-email-button"
                    (click)="deleteEmail()">
                    {{ 'btn_delete' | prefix: this | translate }}
                  </button>

                  <button
                    ft-button
                    appearance="affirmative"
                    [state]="isEditEmailLoading ? 'loading' : 'active'"
                    id="edit-email-button"
                    (click)="saveEmail()">
                    {{ 'btn_save' | prefix: this | translate }}
                  </button>
              </div>

            </div>

          <ng-template #noEmail>
              <strong>
                  {{ 'lbl_status' | prefix: this | translate }}
                  <span *ngIf="true" class="status--bad">{{ 'lbl_status_bad' | prefix: this | translate }}</span>
              </strong>

              <p class="top-8">
                  {{ 'no_secondary_email' | prefix: this | translate: { schoolName: profile.organisation.name, screenName: profile.screenName} }}
              </p>

              <button
                ft-button
                appearance="primary"
                id="add-email-button"
                class="top-8"
                (click)="toggleEmail()">
                {{'btn_email_setup' | prefix: this | translate}}
              </button>
          </ng-template>
        </ft-card-content>
      </ft-card>
</div>
