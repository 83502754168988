import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FtIconModule } from '@fortytwo/ui';

@NgModule({
  imports: [RouterModule, SharedModule, TranslateModule, FtIconModule],
  declarations: [LayoutComponent],
  exports: [LayoutComponent, RouterModule]
})
export class LayoutModule {}
