import { Injectable } from '@angular/core';
import { BaseMapper } from './base-mapper';

import { ParnassysOrganisation } from '../parnassys-organisation';

@Injectable({
  providedIn: 'root'
})
export class OrganisationMapper extends BaseMapper<ParnassysOrganisation> {
  private readonly ORGANISATION_REST_TYPE = 'organisatie.ROrganisatie';

  mapToParnassysEntity(rOrg: any): ParnassysOrganisation {
    const parOrg: ParnassysOrganisation = {
      id: rOrg.links[0].id,
      name: rOrg.naam,
      code: rOrg.korteNaam,
      active: rOrg.actief,
      uuid: rOrg.uuid,
      testSchool: rOrg.testschool,
      twoFactorFreq: rOrg.tweeFactorInvullenFrequentie
    };
    return parOrg;
  }

  mapToParnassysEntities(restAanstellingen: any[]): ParnassysOrganisation[] {
    return (
      restAanstellingen
        .filter(restAanstelling => restAanstelling.organisatie)
        // organisaties met een brinnummer zijn een school
        .filter(restAanstelling => restAanstelling.organisatie.brinNummer)
        .map(restAanstelling => {
          const rOrg = restAanstelling.organisatie;
          return {
            id: rOrg.links[0].id,
            name: rOrg.naam,
            code: rOrg.korteNaam,
            active: rOrg.actief,
            uuid: rOrg.uuid,
            testSchool: rOrg.testschool,
            twoFactorFreq: rOrg.tweeFactorInvullenFrequentie
          };
        })
    );
  }
}
