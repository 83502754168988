import { Injectable } from '@angular/core';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { take } from 'rxjs/operators';
import { AuthService } from '@auth/auth.service';
import { AccountStoreService } from '@core/store/account-store.service';
import { isDevMode } from '@angular/core';

declare let gtag: Function;

/* GOOGLE ANALYTICS SERVICE
 * ---
 * events: https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  GAstreamKey: string;

  public constructor(private auth: AuthService, private account: AccountStoreService) {
    if(!isDevMode()) {
      this.GAstreamKey = (window.location.hostname === 'account.parnassys.net')
        ? 'G-Z4NKZWZRC8'  // Productie
        : 'G-Z1YD51NK68'; // Test
    }
  }
  public RegisterEvent(eventCategory: string, eventAction: string, eventLabel: string = '', eventValue: number = 0): void {
    gtag('event', eventAction, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventValue: eventValue
    });
  }

  public Init(url: string): void {
    gtag('config', this.GAstreamKey, {
      page_path: url,
      custom_map: {
        dimension1: 'IsEmployee',
        dimension2: 'IsMultiProfile',
        dimension3: 'HasTwoFactorEnabled',
        metric1: 'NrOfProfiles'
      }
    });
  }

  public RegisterAccount(): void {
    if (this.auth.isSignedIn()) {
      this.account
        .getIdentity$()
        .pipe(take(1))
        .subscribe((identity: ParnassysIdentity) => {
          gtag('event', 'InitUserEvent', {
            IsEmployee: identity.hasTeacherProfile,
            IsMultiProfile: identity.profiles.length > 1,
            HasTwoFactorEnabled: identity.isTwoFactorProtected,
            NrOfProfiles: identity.profiles.length
          });
        });
    }
  }
}
