import { registerLocaleData } from '@angular/common';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import * as translationEn from '../../../assets/i18n/en.json';
import * as translationNl from '../../../assets/i18n/nl.json';

registerLocaleData(localeNl, 'nl', localeNlExtra);

const TRANSLATIONS = {
  en: translationEn,
  nl: translationNl,
};

export class TranslateUniversalLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang].default);
  }
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return params.key;
  }
}

@NgModule()
export class GlobalTranslateModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('nl');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang?.match(/nl/) ? 'nl' : 'en');
  }

  static forRoot(): ModuleWithProviders<GlobalTranslateModule> {
    return {
      ngModule: GlobalTranslateModule,
      providers: [{ provide: LOCALE_ID, useValue: 'nl-NL' }]
    };
  }
}
