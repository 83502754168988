import { Observable } from 'rxjs';
import { iTranslatable } from '@core/translate/itranslated';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { FtIconRegistry, iconFtShield } from '@fortytwo/ui';

@Component({
  selector: 'par-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IdentityComponent implements iTranslatable {
  readonly langModule = ['account', 'home', 'identity'];
  identity$: Observable<ParnassysIdentity>;

  @Input() public identity: ParnassysIdentity;
  public usernameVisible = false;

  constructor(iconRegistry: FtIconRegistry) {
    iconRegistry.registerIcons([iconFtShield])
  }

  toggleUsername(e: {checked: boolean}): void {
    this.usernameVisible = e.checked;
  }
}
