import { IdentityRestService } from '@core/http/identity-rest.service';
import { Injectable } from '@angular/core';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ParnassysProfile } from '@domain/parnassys-profile';

@Injectable({ providedIn: 'root' })
export class AccountStoreService {
  private identitySubject = new ReplaySubject<ParnassysIdentity>();

  constructor(private identityRestService: IdentityRestService) {}

  public getIdentity$(): Observable<ParnassysIdentity> {
    return this.identitySubject.asObservable();
  }

  public update(): Observable<ParnassysIdentity> {
    return this.identityRestService.fetchIdentity().pipe(tap(identity => this.setIdentity(identity)));
  }

  public setIdentity(identity: ParnassysIdentity): void {
    this.identitySubject.next(identity);
  }

  public getProfile$(profileId: number): Observable<ParnassysProfile> {
    return this.identitySubject.pipe(map(identity => identity.profiles.find(x => x.id === profileId) ?? identity.profiles[0]));
  }

  public setProfile(profile): void {
    this.getIdentity$()
      .pipe(take(1))
      .subscribe(identity => {
        identity.profiles.map(p => {
          if (p.id === profile.id) p = profile;
          return p;
        });

        this.identitySubject.next(identity);
      });
  }
}
