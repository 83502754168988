import { iTranslatable } from '@core/translate/itranslated';
import { Component } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { HostHelper } from '@core/helpers/host.helper';
import { FtIconRegistry, iconFtCross } from '@fortytwo/ui';

@Component({
  selector: 'par-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignoutModalComponent implements iTranslatable {
  readonly langModule = ['modals', 'sign_out'];

  constructor(private authService: AuthService,private iconRegistry: FtIconRegistry) {
    iconRegistry.registerIcons([iconFtCross]);
  }

  signOut(): void {
    const host = sessionStorage.getItem('host') ?? '';
    if (host === 'parro') {
      sessionStorage.removeItem('host');
      HostHelper.CloseWindowFromWebview();
      close();
    } else {
      this.authService.signOut();
    }
  }
}
