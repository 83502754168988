import { ParnassysLink } from '../parnassys-link';
import { ParnassysEntity } from '../parnassys-entity';

export abstract class BaseMapper<T extends ParnassysEntity> {
  public asLinkableWrapper(restEntity: any): any {
    return {
      items: [restEntity]
    };
  }

  protected createLink(id: number, type: string, rel = 'self'): ParnassysLink {
    return {
      id: id,
      rel: rel,
      type: type
    };
  }

  abstract mapToParnassysEntity(restObject: any): T;
}
