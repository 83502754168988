import { ErrorHandler, NgModule } from '@angular/core';
import { CoreRoutingModule } from './core-routing.module';
import { CustomMissingTranslationHandler, GlobalTranslateModule, TranslateUniversalLoader } from './translate/translate.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { AuthInterceptor } from '@auth/auth.interceptor';
import { GlobalErrorHandler } from './error/error.handler';
import { LayoutModule } from '@core/layout/layout.module';

@NgModule({
  imports: [
    CoreRoutingModule,
    GlobalTranslateModule.forRoot(),
    HttpClientModule,
    LayoutModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslateUniversalLoader,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  exports: [GlobalTranslateModule, LayoutModule],
})
export class CoreModule {}
