<ft-card>
  <ft-card-content class="card--flex">
    <h2 class="card__title">
        {{ 'profiles_header' | prefix: this | translate }}{{ identity.profiles.length > 1 ? 's' : '' }}</h2>

    <div class="profile-container">
      <ft-card *ngFor="let profile of identity.profiles; let i = index" class="profile">
        <ft-card-content class="profile-column" >
          <div class="profile__image">
              <img [src]="'/assets/icons/icon_school' + getNumber(i+1) + '.svg'" />
          </div>
          <p class="profile__school bold">{{ profile.organisation.name }}</p>
          <p class="profile__name">{{ profile.screenName }}</p>
          <p class="profile__role" [ngClass]="profile.role == 'Verzorger' ? 'green' : 'blue'">
              {{ profile.role == "Medewerker" ? ('lbl_role_employee' | prefix: this | translate) : ('lbl_role_guardian' | prefix: this | translate) }}
          </p>

          <!-- Redirect Address -->
          <div class="profile-redirect" class="card--flex" *ngIf="profile.email && profile.email !== profile.identityEmail">
              <p class="profile-redirect__label">{{ 'lbl_second_email' | prefix: this | translate }}</p>
              <p class="profile-redirect__value">{{ profile.email }}</p>

              <button
                ft-button
                appearance="secondary"
                [routerLink]="['/account', profile.id]">
                {{ 'btn_redirect_edit' | prefix: this | translate }}
              </button>
            </div>
        </ft-card-content>
      </ft-card>

    </div>
  </ft-card-content>
</ft-card>
