import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { NotificationService } from "./notification.service";
import { FtMessagingModule } from "@fortytwo/ui/components";

@Component({
  standalone: true,
  imports: [FtMessagingModule],
  selector: 'par-toast',
  template: `<ft-toasts [messages]="store.notifications$"></ft-toasts>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {
  store = inject(NotificationService);
}
