import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FtMessagingModule } from '@fortytwo/ui';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatDialogModule,
    FtMessagingModule
  ],
  exports: [
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatDialogModule
  ]
})
export class MaterialModule {}
