<mat-toolbar class="main-menu row between middle" color="primary">
  <mat-toolbar-row class="main-menu__container">
    <div class="main-menu__action" >
      <button
        ft-button
        *ngIf="prev"
        id="prev-button"
        appearance="ghost-destructive"
        size="large"
        iconPosition="only"
        icon="arrow-left"
        (click)="moveBack()">
      </button>
    </div>

    <div class="main-menu__branding">
      <p (click)="home()" id="project-title" class="main-menu__title">
        <strong class="is-hidden-on-mobile">{{ title }}</strong>
        <strong class="is-shown-on-mobile">{{ titleShort }}</strong>
      </p>
    </div>

    <div class="main-menu__action" >
      <button
        ft-button
        appearance="ghost-destructive"
        size="large"
        iconPosition="only"
        icon="sign-out"
        (click)="SignUserOut()">
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
