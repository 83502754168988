<par-main-menu title="Accountportaal"></par-main-menu>

<div class="container" *ngIf="identity$ | async as identity">

    <ft-message class="message" *ngIf="notification$ | async as notification" [type]="notification.type">
      <ft-icon [name]="notification.type"></ft-icon>
      {{ notification.message }}
    </ft-message>


    <par-identity [identity]="identity"> </par-identity>

    <par-profiles [identity]="identity"></par-profiles>

    <div class="add-account">
        <p>{{ 'koppel.lbl_more_accounts' | prefix: this | translate }}</p>
        <a [href]="getInitCodeFlowURL()">{{ 'koppel.lbl_one_login' | prefix: this | translate }}</a>
    </div>
</div>
