<par-main-menu title="{{ 'navbar.pagetitle' | prefix: this | translate }}" [prev]="['/']"></par-main-menu>

<div class="container" *ngIf="identity$ | async as identity">
    <ft-card>
      <ft-card-content class="card--flex">
        <h3 class="card__title">{{ 'subtitle_email' | prefix: this | translate }}</h3>
        <par-edit-email [email]="identity.pidm" class="card-sub-content"></par-edit-email>
      </ft-card-content>
    </ft-card>

    <ft-card>
      <ft-card-content class="card--flex">
        <h3 class="card__title more-info__container">
          {{ 'subtitle_pwd' | prefix: this | translate }}
          <button class="more-info" (click)="openPasswordInfoModal()">
            <ft-icon name="exclamation-alt"></ft-icon>
          </button>
        </h3>
        <par-edit-password class="card-sub-content"></par-edit-password>
      </ft-card-content>
    </ft-card>

    <ft-card *ngIf="identity && identity?.hasTeacherProfile">
      <ft-card-content class="card--flex" id="twofactor">
        <h3 class="card__title">{{ 'subtitle_2fa' | prefix: this | translate }}</h3>
        <par-setup-twofactor [active]="identity.isTwoFactorProtected" *ngIf="identity" [identity]="identity"></par-setup-twofactor>
      </ft-card-content>
    </ft-card>

</div>
