import { InjectionToken } from "@angular/core";
import { AppAuthConfig } from "@auth/config/auth.config";

export const ENV = new InjectionToken<AppConfig>('ENV');

export interface AppConfig {
  auth: AppAuthConfig;

  disableAnimations: boolean;
  parnassysIDPEndpoint: string;
  parnassysEndpoint: string;
  releaseStage: string;
  hotjar: string;
}
