import { Injectable } from '@angular/core';
import { BaseMapper } from './base-mapper';
import { ParnassysIdentity } from '../parnassys-identity';

@Injectable({
  providedIn: 'root'
})
export class IdentityMapper extends BaseMapper<ParnassysIdentity> {
  mapToParnassysEntity(restIdentity: any): ParnassysIdentity {
    let hasTeacherProfile = false;
    restIdentity.accounts?.forEach((profile: any) => {
      if (profile.$type === 'contact.RMedewerkerType') {
        hasTeacherProfile = true;
      }
    });

    return <ParnassysIdentity>{
      pidm: restIdentity.email,
      name: restIdentity.name,
      id: restIdentity.links[0].id,
      active: restIdentity.geactiveerd,
      isTwoFactorProtected: restIdentity.tweeFactorIngesteld,
      hasTeacherProfile: hasTeacherProfile
    };
  }

  mapToRestEntity(entity: ParnassysIdentity): any {
    return undefined;
  }
}
