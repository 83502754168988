import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppConfig, ENV } from '@core/config/app.config';

@Injectable({ providedIn: 'root' })
export class ProfileRestService {
  private config: AppConfig = inject(ENV);
  private http: HttpClient = inject(HttpClient);

  updateCM(cm: string, id: number): Observable<any> {
    return this.http.put(
      this.config.parnassysEndpoint + '/accountsettings/wijzigContactMail',
      {},
      {
        params: {
          id: id.toString(),
          email: cm
        },
        responseType: 'text' as 'json'
      }
    ).pipe(shareReplay());
  }
}
