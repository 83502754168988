<div mat-dialog-title>
  <h3>{{ 'header_signout' | prefix: this | translate }}</h3>
  <button ft-button appearance="ghost-destructive" class="btn btn--link" [mat-dialog-close]="true">
    <ft-icon name="cross"></ft-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <p class="modal-body__subheader">{{ 'subheader_signout' | prefix: this | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    ft-button
    [mat-dialog-close]="true"
    appearance="destructive">
    {{ 'lbl_negative' | prefix: this | translate }}
  </button>
  <button
    ft-button
    (click)="signOut()"
    appearance="primary">
    {{ 'lbl_positive' | prefix: this | translate }}
  </button>
</mat-dialog-actions>
