import { catchError, tap, switchMap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { iTranslatable } from '@core/translate/itranslated';
import { Component, AfterViewInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { UserFeedbackError } from '@core/error/userfeedback.error';
import { Location } from '@angular/common';
import { FtIconRegistry, iconFtCross, iconFtDuplicate, iconFtSend } from '@fortytwo/ui';
import { HostHelper } from '@core/helpers/host.helper';
import { TwofactorRestService } from '@core/http/twofactor-rest.service';
import { AccountStoreService } from '@core/store/account-store.service';
import { GoogleAnalyticsService } from '@core/logging/google-analytics.service';
import { NotificationService } from '@core/error/notifications/notification.service';

@Component({
  selector: 'par-twofactor',
  templateUrl: './twofactor-modal.component.html',
  styleUrls: ['./twofactor-modal.component.scss'],
})
export class TwofactorModalComponent implements iTranslatable, AfterViewInit, OnDestroy {
  public readonly langModule = ['account', 'identity_edit', 'setup_twofactor', 'modal'];
  keyForm: FormGroup;
  readonly storeApp = HostHelper.getStoreApp();

  fields = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
  codeInvalid = false;
  public data: { id: number; username: string; secret: string; isUpdate: boolean };

  @ViewChild('secretRef', { static: false }) private secretRef: ElementRef<HTMLInputElement>;
  @Output() onclose: EventEmitter<any> = new EventEmitter<any>();

  get secretUrl(): string {
    return 'otpauth://totp/ParnasSys:' + this.data.username + '?secret=' + this.data.secret + '&amp;issuer=ParnasSys';
  }

  constructor(
    private notifications: NotificationService,
    public dialogRef: MatDialogRef<TwofactorModalComponent>,
    private twofactorRestService: TwofactorRestService,
    private store: AccountStoreService,
    private GA: GoogleAnalyticsService,
    private readonly location: Location,
    iconRegistry: FtIconRegistry,
    @Inject(MAT_DIALOG_DATA) data: { id: number; username: string; secret: string; isUpdate: boolean }
  ) {
    this.data = data;
    iconRegistry.registerIcons([iconFtCross, iconFtDuplicate, iconFtSend])
    const formFields = {};
    this.fields.forEach(field => {
      formFields[field] = new FormControl('', [Validators.required]);
    });

    this.keyForm = new FormGroup(formFields);
  }

  saveToClipboard(): void {
    this.secretRef.nativeElement.focus();
    this.secretRef.nativeElement.select();
    document.execCommand('copy');
    this.notifications.info('snackbar.twofactor.copied');
  }

  pasteKey(event): void {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');

    const isNotAlphaNumeric: RegExp = /\D+/g;
    const code: string[] = clipboardData.replace(isNotAlphaNumeric, '').substring(0, 6).split('');

    const patch: object = {};

    this.fields.forEach((name, index) => {
      patch[this.fields[index]] = code.length > index ? code[index] : '';
    });
    this.keyForm.patchValue(patch);
    this.checkForm();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.secretRef.nativeElement.value = this.data.secret;
  }

  setInput(event): void {
    event.preventDefault();

    const isAlphaNumeric: RegExp = /^[0-9]$/i;

    if (isAlphaNumeric.test(event.key)) {
      const patch: object = {};
      patch[event.target.id] = event.key;
      this.keyForm.patchValue(patch);

      if (event.target.nextSibling?.type === 'number') {
        event.target.nextSibling.focus();
      }

      this.checkForm();
      return;
    } else {
      const patch: object = {};
      patch[event.target.id] = '';
      this.keyForm.patchValue(patch);
    }
  }

  openAuthenticator() {}

  validateInput(): void {
    if (this.keyForm.valid) {
      this.checkForm();
    } else {
      this.notifications.error('snackbar.twofactor.fail');
    }
  }

  private checkForm(): void {
    const willFail = true;

    if (this.keyForm.valid) {
      this.codeInvalid = false;
      let result = '';

      this.fields.forEach(field => {
        result += this.keyForm.value[field];
      });

      this.keyForm.disable();

      setTimeout(() => {
        this.twofactorRestService
          .configureSecret(this.data.id, this.data.secret, result)
          .pipe(
            catchError(error => {
              this.codeInvalid = true;
              this.keyForm.reset();
              this.GA.RegisterEvent('Identity', 'Edit', 'EnableTwoFactor', 0);
              this.keyForm.enable();

              return throwError(new UserFeedbackError('snackbar.twofactor.fail'));
            }),
            tap(() => {
              this.GA.RegisterEvent('Identity', 'Edit', 'EnableTwoFactor', 1);
              this.notifications.success('snackbar.twofactor.success');

              const pathWithoutHash = this.location.path(false);
              this.location.replaceState(pathWithoutHash);

              this.keyForm.reset();
              this.dialogRef.close();
            }),
            switchMap(() => this.store.update())
          )
          .subscribe(() => {
            this.keyForm.enable();
          });
      }, 1500);
    }
  }
}
