import { ActivatedRoute } from '@angular/router';
import { DisableTwofactorModalComponent } from './disable-twofactor-modal/disable-twofactor-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TwofactorModalComponent } from './twofactor-modal/twofactor-modal.component';
import { iTranslatable } from '@core/translate/itranslated';
import { Component, Input, OnInit } from '@angular/core';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { TwofactorRestService } from '@core/http/twofactor-rest.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'par-setup-twofactor',
  templateUrl: './setup-twofactor.component.html',
  styleUrls: ['./setup-twofactor.component.scss']
})
export class SetupTwoFactorComponent implements OnInit, iTranslatable {
  public readonly langModule = ['account', 'identity_edit', 'setup_twofactor'];

  @Input() active = false;
  @Input() identity: ParnassysIdentity;
  private secret: string;

  constructor(private dialog: MatDialog, private twofactor: TwofactorRestService, private route: ActivatedRoute) {
    this.secret = 'not available..';
  }

  ngOnInit(): void {
    this.twofactor.getSecret$().subscribe(secret => {
      this.secret = secret;
      this.route.fragment.pipe(take(1)).subscribe((fragment: string) => {
        if (fragment === 'twofactor') this.openTwofactorModal();
      });
    });
  }

  openTwofactorModal(): void {
    const dialogRef = this.dialog.open(TwofactorModalComponent, {
      panelClass: 'large',
      data: {
        id: this.identity.id,
        username: this.identity.name,
        secret: this.secret,
        isUpdate: this.active
      }
    });
  }
  openDisableTwofactorModal(): void {
    const dialogRef = this.dialog.open(DisableTwofactorModalComponent, {
      panelClass: 'small',
      data: {
        id: this.identity.id,
        username: this.identity.name,
        secret: this.secret
      }
    });
  }
}
