import { Observable, take } from 'rxjs';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { Component, ViewEncapsulation } from '@angular/core';
import { iTranslatable } from '@core/translate/itranslated';
import { AccountStoreService } from '@core/store/account-store.service';
import { PasswordInfoModalComponent } from '@shared/components/dialogs/password-info/password-info.component';
import { MatDialog } from '@angular/material/dialog';
import { FtIconRegistry, iconFtExclamationAlt } from '@fortytwo/ui';

@Component({
  selector: 'par-identity-edit',
  templateUrl: './identity-edit.component.html',
  styleUrls: ['./identity-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IdentityEditComponent implements iTranslatable {
  readonly langModule = ['account', 'identity_edit'];
  identity$: Observable<ParnassysIdentity>;

  constructor(private accountStore: AccountStoreService, private dialog: MatDialog, iconRegistry: FtIconRegistry) {
    this.identity$ = this.accountStore.getIdentity$();
    iconRegistry.registerIcons([iconFtExclamationAlt]);
  }

  openPasswordInfoModal(): void {
    this.identity$.pipe(take(1)).subscribe(
      identity => {
        this.dialog.open(PasswordInfoModalComponent, {
          data: { medewerker: identity.hasTeacherProfile },
          panelClass: 'small'
        });
      }
    );
  }
}
