import { AuthGuard } from '@auth/auth.guard';
import { IdentityEditComponent } from './identity-edit/identity-edit.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { IndexComponent } from './index/index.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { KoppelConfirmComponent } from './koppel-confirm/koppel-confirm.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: IndexComponent
  },
  {
    path: 'identity',
    canActivate: [AuthGuard],
    component: IdentityEditComponent
  },
  {
    path: 'account/:id',
    canActivate: [AuthGuard],
    component: ProfileEditComponent
  },
  {
    path: 'koppel-confirm',
    component: KoppelConfirmComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeaturesRoutingModule {}
