<div mat-dialog-title>
  <h3>{{ (data.isUpdate ? 'header_update' : 'header_setup') | prefix: { langModule: this.langModule } | translate }}</h3>
  <button
    ft-button
    [mat-dialog-close]="true"
    appearance="ghost-monochrome"
    icon="cross">
  </button>
</div>
<mat-dialog-content>
  <div class="modal-body">
    <p class="modal-body__text modal-body__text--mobile">
      {{ 'txt_mob_presecret' | prefix: { langModule: this.langModule } | translate }}
    </p>
    <p class="modal-body__text modal-body__text--desktop">
      {{ 'txt_pc_presecret' | prefix: { langModule: this.langModule } | translate }} </p
    ><div class="modal-body__secret">
      <input type="text" class="modal-body__secret-field" readonly type="text" value="..." name="secret" autocomplete="off" #secretRef />

      <button
        ft-button
        appearance="ghost"
        href="#"
        (click)="saveToClipboard()"
        iconPosition="only"
        class="modal-body__secret-copy"
        icon="duplicate">
    </button>
    </div>

    <p class="modal-body__text modal-body__text--mobile">
      {{ 'txt_mob_postsecret' | prefix: { langModule: this.langModule } | translate }}
      <a href="{{ storeApp }}">Google Authenticator</a>
    </p>

    <div class="modal-body__text modal-body__prevalidation">
      <p class="modal-body__key--text">
        <b>{{ 'txt_prevalidation' | prefix: { langModule: this.langModule } | translate }}</b>
      </p>
      <form
        class="modal-body__key"
        [ngClass]="keyForm.disabled ? 'modal-body__key--loading' : codeInvalid ? 'modal-body__key--bad' : ''"
        [formGroup]="keyForm"
        #form
      >
        <input
          *ngFor="let field of fields"
          class="modal-body__key-field modal-field modal-body__key-field--{{ field }}"
          type="number"
          maxlength="1"
          placeholder="0"
          [formControlName]="field"
          [id]="field"
          (keypress)="setInput($event)"
          (paste)="pasteKey($event)"
          autocomplete="off"
        />
        <button
          ft-button
          appearance="affirmative"
          href="#"
          (click)="validateInput()"
          size="small"
          iconPosition="only"
          class="modal-body__key-btn"
          icon="send">

        </button>
      </form>
    </div>

    <qrcode class="modal-body__qrcode" [qrdata]="secretUrl" [width]="256" [errorCorrectionLevel]="'M'" elementType="png"> </qrcode>
  </div>
</mat-dialog-content>
