import { UserFeedbackError } from './userfeedback.error';

import Bugsnag from '@bugsnag/js';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@core/error/error.service';
import { environment } from '@environments/environment';
import { NotificationService } from './notifications/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private errorService: ErrorService,
    private notifications: NotificationService,
    private zone: NgZone,
    private injector: Injector
  ) {
    if (environment.production) {
      Bugsnag.start({ apiKey: '06cb3f54d80a01ff0aec9934b2aa25d2' });
    } else {
      console.warn('*************************\n*   TESTMODUS ENABLED   *\n*************************');
      console.warn('Expect precise logging! ');
    }
  }

  handleError(error: any): void {
    if (!(error instanceof UserFeedbackError)) {
      if (environment.production) {
        Bugsnag.notify(error);
      } else {
        console.error(error);
      }
    }

    if (error.status && error.status === 401) {
      const router = <Router>this.injector.get(Router);
      router.navigate(['login']);
    }

    this.showErrorToUser(this.getUserErrorMessage(error));
  }

  getUserErrorMessage(error: any): string {
    return error instanceof HttpErrorResponse
      ? this.errorService.getServerErrorMessage(error)
      : this.errorService.getClientErrorMessage(error);
  }

  showErrorToUser(message: string): void {
    this.zone.run(() => {
      setTimeout(() => this.notifications.error(message), 0);
    });
  }
}
