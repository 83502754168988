import { AppAuthConfig } from "./auth.config";
import { AuthConfig } from "angular-oauth2-oidc";

export class AuthConfigBuilder {

  private config: AuthConfig;

  private constructor(conf: AppAuthConfig) {
    conf = this.sanitize(conf);

    this.config = {
      ...this.setBaseSettings(),
      ...this.setAuthUrlSettings(conf),
      clientId: conf.clientId,
      issuer: conf.issuer,
      requireHttps: conf.requireHTTPS,
      useSilentRefresh: false,            // Uses iFrames which are forbidden in Psys
      timeoutFactor: 0.25,                // 1.0 = 2 hours
    };
  }

  public get get(): AuthConfig {
    return {...this.config}; // defensive copy
  }

  public build(): AuthConfig {
    return this.get;
  }

  public set(props: Partial<AuthConfig>): AuthConfigBuilder {
    this.config = {
      ...this.config,
      ...props
    }
    return this;
  }

  private sanitize(conf: AppAuthConfig): AppAuthConfig {
    return {
      ...conf,
      client_url: conf.client_url ?? window.location.origin
    }
  }

  private setBaseSettings(): Partial<AuthConfig> {
    return {
      customQueryParams: { claims: '{ "id_token": { "name": null} }' },
      scope: 'openid account',
      responseType: 'code',
      oidc: true,
      showDebugInformation: false,
      strictDiscoveryDocumentValidation: false
    }
  }

  private setAuthUrlSettings(conf: AppAuthConfig): Partial<AuthConfig> {
    return {
      loginUrl: conf.IDP_url + '/authorize',
      logoutUrl: conf.IDP_url + '/logout',
      tokenEndpoint: conf.IDP_url + '/token',
      userinfoEndpoint: conf.IDP_url + '/userinfo',

      redirectUri: conf.client_url + '/oauth2',
      postLogoutRedirectUri: conf.client_url,
    }
  }

  public static from(conf: AppAuthConfig): AuthConfigBuilder {
    return new AuthConfigBuilder(conf);
  }
}
