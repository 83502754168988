import { OrganisationMapper } from './organisation-mapper';
import { Injectable } from '@angular/core';
import { BaseMapper } from './base-mapper';
import { ParnassysProfile } from '../parnassys-profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileMapper extends BaseMapper<ParnassysProfile> {
  constructor(private organisationMapper: OrganisationMapper) {
    super();
  }

  mapToParnassysEntity(rProfile: any): ParnassysProfile {
    const account: ParnassysProfile = {
      id: rProfile.id,
      screenName: rProfile.weergavenaam,
      lastname: rProfile.achternaam,
      email: rProfile.email,
      role: rProfile.readableType,
      username: rProfile.gebruikersnaam,
      identityEmail: rProfile.identiteitEmail,
      actief: rProfile.actief,
      twoFactorActive: rProfile.identiteitTweeFactorActief,
      accountStatus: rProfile.accountStatus
    };

    if (rProfile.organisatie) {
      account.organisation = this.organisationMapper.mapToParnassysEntity(rProfile.organisatie);
    }
    return account;
  }

  mapToRestEntity(entity: ParnassysProfile): any {
    return undefined;
  }
}
