import { Component} from '@angular/core';

@Component({
  selector: 'par-layout',
  templateUrl: './layout.component.html',
  styles: [``]
})
export class LayoutComponent {

}
