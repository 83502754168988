import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { SharedModule } from '@shared/shared.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthRoutingModule } from './pages/auth-routing.module';
import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';
import { LoginModule } from './pages/login/login.module';
import { SignoutComponent } from './pages/signout/signout.component';

@NgModule({
  imports: [OAuthModule.forRoot(), AuthRoutingModule, SharedModule, LoginModule],
  declarations: [AuthCallbackComponent, SignoutComponent],
  providers: [AuthService]
})
export class AuthModule {}
