import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefix'
})
export class LanguagePrefixerPipe implements PipeTransform {
  transform(value: string, prefix: any): string {
    if (prefix.langModule !== undefined) {
      const url = Array.isArray(prefix.langModule) ? prefix.langModule.join('.') : prefix.langModule;

      value = url === '' ? value : url + '.' + value;
    } else {
      const component: string = typeof prefix;
      console.warn('Component "' + component + '" does not inherit iTranslated');
    }

    return value.toLowerCase().trim();
  }
}
