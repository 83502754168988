import { SharedModule } from '@shared/shared.module';
import { NgModule } from '@angular/core';
import { NoSessionAuthService } from './no-session-auth.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { KoppelAuthRoutingModule } from './pages/koppel-auth-routing.module';

@NgModule({
  imports: [OAuthModule.forRoot(), KoppelAuthRoutingModule, SharedModule],
  providers: [NoSessionAuthService]
})
export class KoppelAuthModule {}
