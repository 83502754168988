<form class="pidm-form" [formGroup]="emailForm">
    <ft-formfield [nolabel]="true">
      <input ftInputfield type="text" id="pidm" formControlName="pidm" placeholder="John.Doe@parnassys.net" name="email" />
      <label for="pidm" class="ft-formfield__label">{{ 'lbl_emailfield' | prefix: this | translate }}</label>
    </ft-formfield>
</form>

<div class="card-row-content">
  <button
    ft-button
    appearance="secondary"
    [state]="isEmailLoading ? 'loading' : 'active'"
    (click)="updatePidm()">
    {{'btn_email' | prefix: this | translate}}
  </button>

  <button
    ft-button
    appearance="destructive"
    (click)="cancel()">
    {{ 'btn_cancel' | prefix: this | translate }}
  </button>
</div>
