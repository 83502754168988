import { GoogleAnalyticsService } from '@core/logging/google-analytics.service';
import { AccountStoreService } from '@core/store/account-store.service';
import { TwofactorRestService } from '@core/http/twofactor-rest.service';
import { iTranslatable } from '@core/translate/itranslated';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserFeedbackError } from '@core/error/userfeedback.error';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FtIconRegistry, iconFtCross } from '@fortytwo/ui';
import { NotificationService } from '@core/error/notifications/notification.service';

@Component({
  selector: 'par-sign-out',
  templateUrl: './disable-twofactor-modal.component.html',
  styleUrls: ['./disable-twofactor-modal.component.scss']
})
export class DisableTwofactorModalComponent implements iTranslatable {
  public readonly langModule = ['account', 'identity_edit', 'setup_twofactor', 'modal_disable'];

  constructor(
    private GA: GoogleAnalyticsService,
    private twofactorRestService: TwofactorRestService,
    private store: AccountStoreService,
    private notifications: NotificationService,
    public dialogRef: MatDialogRef<DisableTwofactorModalComponent>,
    iconRegistry: FtIconRegistry,
    @Inject(MAT_DIALOG_DATA) private data: { id: number; username: string; secret: string }
  ) {
    iconRegistry.registerIcons([iconFtCross])
  }

  onRemoveTwofactor(): void {
    this.twofactorRestService
      .removeSecret(this.data.id)
      .pipe(
        catchError(error => {
          this.GA.RegisterEvent('Identity', 'Edit', 'EnableTwoFactor', 0);

          return throwError(new UserFeedbackError('snackbar.twofactor.fail'));
        }),
        tap(() => {
          this.GA.RegisterEvent('Identity', 'Edit', 'EnableTwoFactor', 1);
          this.notifications.success('snackbar.twofactor.success');
          this.dialogRef.close();
        }),
        switchMap(() => this.store.update())
      )
      .subscribe();
  }
}
