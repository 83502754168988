import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenProfile'
})
export class ProfileShortenPipe implements PipeTransform {
  transform(value: string, replacement: string): string {
    if (value.length > 25) {
      return replacement;
    }
    return value;
  }
}
