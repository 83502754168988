<div class="row row--begin">
  <strong id="status-label">
    {{'lbl_current_status' | prefix: this | translate}}
    <span *ngIf="active" class="status--good">{{ 'lbl_state_active' | prefix: this | translate }} </span>
    <span *ngIf="!active" class="status--bad">{{ 'lbl_state_unactive' | prefix: this | translate }} </span> </strong>
  <br />
  <p class="is-small top-8">
    {{'txt_body_1' | prefix: this | translate}}<br /><br />{{'txt_body_2' | prefix: this | translate}}
    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl&gl=US"
      target="blank">
      Google Authenticator
    </a> {{'txt_body_3' | prefix: this | translate}}
    <a href="https://parnassys.zendesk.com/hc/nl/articles/210119605"
      target="blank">{{'link_article' | prefix: this | translate}}</a>.
  </p>
</div>

<div class="actions" *ngIf="active">
  <button
    ft-button
    appearance="secondary"
    id="remove-twofactor"
    (click)="openDisableTwofactorModal()">
    {{'btn_delete' | prefix: this | translate}}
  </button>
  <button
    ft-button
    appearance="primary"
    id="update-twofactor"
    (click)="openTwofactorModal()">
    {{'btn_update' | prefix: this | translate}}
  </button>
</div>
<div class="actions" *ngIf="!active">
  <button
    ft-button
    appearance="primary"
    id="setup-twofactor"
    (click)="openTwofactorModal()">
    {{'btn_setup' | prefix: this | translate}}
  </button>
</div>
