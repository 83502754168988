import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'par-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private authService = inject(AuthService);

  public strokeWidth = 3;
  public diameter = 60;

  ngOnInit(): void {
    this.authService.signIn();
  }
}
