<ft-card>
  <ft-card-content class="card--flex">
    <h2 class="card__title">{{ 'header_signin' | prefix: this | translate }}</h2>
    <div class="profile-table">
        <div class="profile-table__row">
            <p class="profile-table__label">{{ 'lbl_pidm' | prefix: this | translate }}</p>
            <p class="profile-table__value">{{ identity.pidm }}</p>
        </div>
        <div class="profile-table__row">
            <p class="profile-table__label">{{ 'lbl_password' | prefix: this | translate }}</p>
            <p class="profile-table__value">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</p>
        </div>
        <div class="profile-table__row" *ngIf="identity && identity?.hasTeacherProfile">
            <p class="profile-table__label">{{ 'lbl_twofactor' | prefix: this | translate }}</p>
            <p class="profile-table__value">
                <a href="#" class="twofactor__label" routerLink="/identity" fragment="twofactor"
                    *ngIf="!identity.isTwoFactorProtected">
                    <p class="twofactor__icon"><ft-icon name="shield" class=""></ft-icon></p>
                    {{ 'btn_setup2fa' | prefix: this | translate }}
                </a>
            </p>
            <span *ngIf="identity.isTwoFactorProtected" class="twofactor__active">
              <ft-icon name="shield" class=""></ft-icon>
                {{ 'btn_2fa_active' | prefix: this | translate }}
            </span>
        </div>
        <div class="profile-table__row username"
            [ngClass]="usernameVisible ? 'username--open' : 'username--closed'">
            <p class="profile-table__label">{{ 'lbl_username' | prefix: this | translate }}</p>
            <p class="profile-table__value">{{ identity?.name }}</p>
        </div>
    </div>

    <div class="show-username">
        <ng-template [ngIf]="identity && identity?.hasTeacherProfile">
              <ft-slide-toggle
                class="show-username__trigger"
                name="showUsernamer"
                value="I_UNDERSTAND"
                (valueChange)="toggleUsername($event)">
                {{ 'lbl_showuname' | prefix: this | translate }}
            </ft-slide-toggle>
        </ng-template>
    </div>

    <div class="row row--middle row--end login-btn">
      <button ft-button appearance="secondary" routerLink="/identity">
        {{ 'btn_edit' | prefix: this | translate }}
      </button>
    </div>
  </ft-card-content>
</ft-card>
