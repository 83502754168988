import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { AppConfig, ENV } from '@core/config/app.config';

@Injectable({ providedIn: 'root' })
export class TwofactorRestService {
  private config: AppConfig = inject(ENV);
  private http = inject(HttpClient);

  public getSecret$(): Observable<any> {
    return this.http
      .get(this.config.parnassysIDPEndpoint + '/identiteit/2fa/generateSecret', { responseType: 'text' })
      .pipe(shareReplay());
  }

  public configureSecret(identity: number, secret: string, key: string): Observable<any> {
    return this.http.post(this.config.parnassysIDPEndpoint + '/identiteit/' + identity + '/2fa/configure', {
      secret: secret,
      token: key
    });
  }

  public removeSecret(identity: number): Observable<any> {
    return this.http.delete(this.config.parnassysIDPEndpoint + '/identiteit/' + identity + '/2fa');
  }
}
