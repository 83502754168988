import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig, ENV } from '@core/config/app.config';
import { ParnassysIdentity } from '@domain/parnassys-identity';
import { NoSessionAuthService } from '@core/koppel-auth/no-session-auth.service';
import { AccountStoreService } from '@core/store/account-store.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '@core/error/notifications/notification.service';

@Component({
  selector: 'par-bevestig-koppel',
  templateUrl: './koppel-confirm.component.html',
  styleUrls: ['./koppel-confirm.component.scss']
})
export class KoppelConfirmComponent implements OnInit, OnDestroy {
  private config: AppConfig = inject(ENV);

  readonly langModule = ['account', 'home'];
  private identitySub: Subscription;
  identity: ParnassysIdentity;
  public prev: any;
  token: string;

  secondaryIdentity: ParnassysIdentity;

  constructor(
    private router: Router,
    private accountStore: AccountStoreService,
    private noSessionAuthService: NoSessionAuthService,
    private notifications: NotificationService
  ) {}

  ngOnInit(): void {
    if (!sessionStorage.getItem('secondaryToken')) {
      this.notifications.info('snackbar.koppel.cancel')
      this.router.navigate(['/']);
    }

    this.identitySub = this.accountStore.getIdentity$().subscribe(identity => {
      this.identity = identity;

      const secondaryProfile = this.noSessionAuthService.parseJwt(sessionStorage.getItem('secondaryToken'));
      for (let i = 0; i < this.identity.profiles.length; i++) {
        if (this.identity.profiles[i].id == secondaryProfile.sub) {
          // The user has logged in with the same two accounts!
          this.notifications.error({
            msg:'snackbar.koppel.already_logged_in',
            prop: this.identity.pidm
          })

          this.noSessionAuthService.logout().subscribe(
            () => {
              this.noSessionAuthService.clearAndGoHome();
            },
            () => {
              this.noSessionAuthService.clearAndGoHome();
            }
          );
        }
      }
    });

    this.noSessionAuthService.getIdentity().subscribe(identity => {
      this.secondaryIdentity = identity;
    });
  }

  ngOnDestroy(): void {
    if (this.identitySub) this.identitySub.unsubscribe();
  }

  koppel() {
    this.noSessionAuthService.koppel();
  }

  cancel() {
    this.notifications.info('snackbar.koppel.cancel');
    this.noSessionAuthService.logout().subscribe(
      () => {
        this.noSessionAuthService.clearAndGoHome();
      },
      () => {
        this.noSessionAuthService.clearAndGoHome();
      }
    );
  }
}
