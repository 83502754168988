import { GoogleAnalyticsService } from '@core/logging/google-analytics.service';
import { Router, ActivatedRoute, NavigationEnd, Event } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'par-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'parnassys-accountportaal';
  private routeSub: Subscription;
  private GAsub: Subscription;

  constructor(private GA: GoogleAnalyticsService, private router: Router, private route: ActivatedRoute) {
    this.GAsub = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          this.GA.Init(event.urlAfterRedirects);
          this.GA.RegisterAccount();
        })
      )
      .subscribe();
    this.routeSub = this.route.queryParams
      .pipe(
        filter(value => value['host'] !== undefined),
        tap(value => {
          sessionStorage.setItem('host', value['host']);
          this.router.navigate([], {
            queryParams: { host: null },
            queryParamsHandling: 'merge'
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.GAsub) {
      this.GAsub.unsubscribe();
    }
  }
}
