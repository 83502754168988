import { NotificationType, Notification } from './notification';
import { Injectable, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  translateService = inject(TranslateService);

  private _messages: Subject<Notification> = new Subject();

  public get notifications$(): Observable<Notification> {
    return this._messages.asObservable();
  }

  public notify(rawMsg: string|{msg: string, prop: any}, type: NotificationType) {
    let variables: undefined|{value1: string} = undefined;
    if(typeof rawMsg !== 'string' && !!rawMsg.msg) {
      variables = { value1: rawMsg.prop };
      rawMsg = rawMsg.msg;
    }

    return this._messages.next({
      message: this.translateService.instant(rawMsg as string, variables),
      type
    });
  }

  public error(rawMsg: string|{msg: string,prop:any}) {
    this.notify(rawMsg, 'error')
  }

  public warn(rawMsg: string|{msg: string,prop:any}) {
    this.notify(rawMsg, 'warning')
  }

  public info(rawMsg: string|{msg: string,prop:any}) {
    this.notify(rawMsg, 'info')
  }

  public success(rawMsg: string|{msg: string,prop:any}) {
    this.notify(rawMsg, 'success')
  }
}
