import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  static isRouteError(message: string): boolean {
    return message.includes('Error: Cannot match any routes');
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    return [400, 401, 403, 404, 413, 415, 500].includes(error.status) ? 'error.' + error.status.toString() : 'Something went wrong';
  }

  getClientErrorMessage(error: Error): string {
    if (ErrorService.isRouteError(error.message)) {
      return 'error.route.not.found';
    } else {
      return error.message;
    }
  }
}
